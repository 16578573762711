import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import Section from '@ui/data-display/Section'
import { DarkModeSelect } from '@ui/data-entry/DarkModeSelect'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'

import { ColorThemeSelect } from './components/ColorThemeSelect'
import { ThemeOptions } from './components/ThemeOptions'
import { ThemeVariantSelect } from './components/ThemeVariantSelect'
import { TypographySelect } from './components/TypographySelect'

export function DesignOptions({ site, onSubmit }) {
  const { t } = useTranslation('web/appearance')

  const handleSubmit = useCallback(
    formData => {
      onSubmit({
        ...site,
        design: {
          ...site.design,
          options: formData,
        },
      })
    },
    [onSubmit, site]
  )

  const formData = useMemo(() => {
    return (
      site.design?.options || {
        variant: 'alps',
        colorTheme: 'denim',
      }
    )
  }, [site])

  return (
    <Form
      data={formData}
      onSubmit={handleSubmit}
      id={`${site.id}-${site.updatedAt}`}
    >
      <Section title={t('designOptions')}>
        <Section.Body>
          <ThemeVariantSelect site={site} />
          <DarkModeSelect
            label={t('designAppearance')}
            help={t('designAppearanceHelp')}
            name="appearance"
            shouldUnregister
            required
            enableAuto
          />
          <ColorThemeSelect site={site} />
          <TypographySelect site={site} />
          <ThemeOptions site={site} />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}

DesignOptions.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
