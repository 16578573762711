import PropTypes from 'prop-types'
import React from 'react'

import {
  FloatingPortal,
  autoUpdate,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react'
import { Combobox, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import ClearButton from '@ui/buttons/ClearButton'
import Loading from '@ui/feedback/Loading'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * Basic Autocomplete component without Field
 */
export default function AutocompleteInput({
  by,
  children,
  className = '',
  disabled,
  displayIcon,
  displayMultiple,
  displayValue,
  loading = false,
  loadingText = '',
  multiple,
  name,
  nullable = true,
  onChange,
  onSearch,
  placeholder,
  searchText,
  showValueInline = true,
  value = '',
}) {
  const { t } = useTranslation()
  const { floatingStyles, refs } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    transform: false,
    middleware: [
      shift(),
      offset(4),
      size({
        apply({ availableWidth, availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.min(availableWidth, rects.reference.width)}px`,
            maxHeight: `${availableHeight - 30}px`, // make the list grow vertically, butleave 30px to the bottom of the page
          })
        },
      }),
    ],
  })

  const hasValue = multiple
    ? Array.isArray(value) && value.length > 0
    : Boolean(value)

  const onItemRemove = React.useCallback(
    itemKey => {
      if (
        typeof onChange !== 'function' ||
        !Array.isArray(value) ||
        value.length === 0 ||
        !itemKey
      ) {
        return
      }

      onChange(
        value.filter(key => {
          if (typeof key === 'object') {
            return itemKey !== key[by ?? 'id']
          }
          return itemKey !== key
        })
      )
    },
    [by, onChange, value]
  )

  const onClear = React.useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <React.Suspense fallback={<div />}>
      <Combobox
        value={value || (multiple ? [] : null)}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        nullable={nullable}
        by={by}
        name={name}
        as={React.Fragment}
      >
        {({ open }) => (
          <div className="relative flex max-w-full flex-grow flex-col">
            {hasValue && multiple && !showValueInline && (
              <div className="-mb-1 flex flex-col rounded-t border border-b-0 bg-gray-50">
                {value.length > 1 && (
                  <div className="flex items-center justify-between border-b px-2 py-1">
                    <span className="text-sm text-gray-500">
                      {t('selected', { count: value.length })}
                    </span>
                    <ClearButton onClick={onClear} showLabel />
                  </div>
                )}
                <div className="flex flex-wrap gap-1 p-2 pb-3">
                  {typeof displayMultiple === 'function'
                    ? displayMultiple(value, onItemRemove)
                    : displayMultiple}
                </div>
              </div>
            )}
            <div
              className={`form-input isolate flex w-full flex-row items-center justify-between gap-2 rounded border-gray-300 focus-within:border-primary-500 focus-within:outline-none focus-within:ring focus-within:ring-primary-200 ${className}`}
              ref={refs.setReference}
            >
              {displayIcon && <div className="shrink-0">{displayIcon}</div>}

              <div className="flex flex-1 flex-row flex-wrap items-center justify-start gap-1 overflow-hidden">
                <Combobox.Button
                  as="div"
                  className="flex w-full flex-grow flex-wrap items-center justify-start gap-1 text-start"
                >
                  {hasValue &&
                    (multiple && showValueInline
                      ? typeof displayMultiple === 'function'
                        ? displayMultiple(value, onItemRemove)
                        : displayMultiple
                      : typeof displayValue === 'function'
                        ? displayValue(value)
                        : displayValue)}
                  {(multiple || !hasValue) && (
                    <Combobox.Input
                      autoComplete="off" // prevent browser's suggestions
                      className="w-3 flex-grow px-2 focus:outline-none"
                      placeholder={placeholder}
                      displayValue={v => {
                        if (!showValueInline || !hasValue) return undefined
                        return typeof displayValue === 'function'
                          ? displayValue(v)
                          : displayValue
                      }}
                      onChange={e => onSearch(e.target.value)}
                      value={searchText}
                    />
                  )}
                  {loading && (
                    <div className="shrink-0 text-sm text-gray-500">
                      {loadingText || t('loading')}
                    </div>
                  )}
                </Combobox.Button>
              </div>
              {!disabled && hasValue && !multiple && (
                <div className="-mx-2 shrink-0 pt-px child:p-0">
                  <ClearButton onClick={onClear} />
                </div>
              )}

              <Combobox.Button
                className={`text-sm text-gray-500 transition-all duration-150 ease-in-out ${
                  open ? 'rotate-180' : ''
                }`}
              >
                <Icon name="chevron-down" />
              </Combobox.Button>
            </div>

            {children?.length > 0 && (
              <FloatingPortal id={name}>
                <React.Suspense fallback={<Loading />}>
                  <Transition
                    show={open}
                    enter="transition duration-150 ease-out"
                    enterFrom="-translate-y-4 opacity-0"
                    enterTo="translate-y-0 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="translate-y-0 opacity-100"
                    leaveTo="-translate-y-4 opacity-0"
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="z-max w-full overflow-y-auto rounded-lg border bg-white shadow-xl"
                  >
                    <Combobox.Options>
                      {React.Children.map(
                        children?.filter(child => !!child),
                        child =>
                          React.cloneElement(child, {
                            siblingHasValue: hasValue,
                          })
                      )}
                    </Combobox.Options>
                  </Transition>
                </React.Suspense>
              </FloatingPortal>
            )}
          </div>
        )}
      </Combobox>
    </React.Suspense>
  )
}
AutocompleteInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  displayIcon: PropTypes.node,
  displayMultiple: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  displayValue: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  multiple: PropTypes.bool,
  nullable: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.node,
  by: PropTypes.string,
  name: PropTypes.string,
  searchText: PropTypes.string,
  showValueInline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}
