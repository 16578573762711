import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { ColorThemeSelect } from '../appearance/DesignOptions/components/ColorThemeSelect'

export function LayoutOptions({ site }) {
  const { t } = useTranslation('designs/adventist')

  return (
    <>
      <ColorThemeSelect
        help={t('layoutColorThemeHelp')}
        label={t('layoutColorTheme')}
        name="design.colorTheme"
        site={site}
      />
    </>
  )
}

LayoutOptions.propTypes = {
  site: PropTypes.object.isRequired,
}
