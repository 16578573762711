import PropTypes from 'prop-types'
import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const verticalAlignClasses = {
  top: 'items-start',
  center: 'items-center',
  bottom: 'items-end',
}
export default function SortableItem({
  className = '',
  children,
  disabled = false,
  handleClass = '',
  handleVerticalAlign = 'center',
  id,
  showHandle = true,
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      className={`relative flex flex-row justify-between gap-2 ${
        isDragging ? 'z-10' : 'z-0'
      } ${verticalAlignClasses[handleVerticalAlign]} ${className}`}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...(showHandle || disabled ? {} : listeners)}
    >
      {showHandle && (
        <div
          className={`flex w-4 shrink-0 py-4 text-gray-300 ${
            disabled ? 'cursor-not-allowed opacity-50' : 'text-gray-300'
          } ${isDragging ? 'cursor-grabbing' : 'cursor-grab'} ${handleClass}`}
          {...listeners}
        >
          <Icon name="bars" />
        </div>
      )}
      <div className={`w-full ${isDragging ? 'shadow-xl' : ''}`}>
        {React.Children.map(children, child =>
          React.cloneElement(child, { isDragging })
        )}
      </div>
    </div>
  )
}
SortableItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleClass: PropTypes.string,
  handleVerticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  id: PropTypes.string.isRequired,
  showHandle: PropTypes.bool,
}
