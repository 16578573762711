import PropTypes from 'prop-types'
import React from 'react'

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

export function SortableListContext({
  children,
  disabled, // Whether the list is disabled (default: false)
  handleDragEnd,
  items, // List of items to render (default: [], but required in practice)
  name, // Name of the list (**required**)
}) {
  // We use two sensors to allow for both mouse and keyboard interactions
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 2 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        id={name}
        items={items}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        {children}
      </SortableContext>
    </DndContext>
  )
}

SortableListContext.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  handleDragEnd: PropTypes.func.isRequired,
  items: PropTypes.array,
  name: PropTypes.string.isRequired,
}
