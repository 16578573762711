import React from 'react'
import PropTypes from 'prop-types'

import Button from '@ui/buttons/Button'

const verticalAlignClasses = {
  top: 'items-start',
  center: 'items-center',
  bottom: 'items-end',
}

export default function SortableItemWrapper({
  actions,
  actionsVerticalAlign = 'center',
  className = '',
  children,
  deleteDisabled,
  deleteClass = '',
  extra,
  innerClass = '',
  isDragging,
  noPadding,
  onDelete,
}) {
  return (
    <div
      className={`flex flex-grow justify-between rounded-lg transition-all duration-300 ease-in-out ${
        noPadding ? '' : '-mx-3 gap-3 bg-white py-3 px-3'
      } ${
        isDragging
          ? 'z-20 overflow-hidden shadow-xl ring-2 ring-primary-500'
          : 'z-0 shadow-none ring-0'
      } ${verticalAlignClasses[actionsVerticalAlign]} ${className}`}
    >
      <div
        className={`flex flex-grow items-start ${
          noPadding ? '' : 'gap-3'
        } ${innerClass}`}
      >
        {children}
      </div>
      {extra}
      {actions}
      {onDelete && (
        <Button
          className={deleteClass}
          onClick={onDelete}
          icon="trash-alt"
          variant="danger-light"
          disabled={deleteDisabled}
        />
      )}
    </div>
  )
}
SortableItemWrapper.propTypes = {
  actions: PropTypes.node,
  actionsVerticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  className: PropTypes.string,
  children: PropTypes.node,
  deleteClass: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  extra: PropTypes.node,
  innerClass: PropTypes.string,
  isDragging: PropTypes.bool,
  noPadding: PropTypes.bool,
  onDelete: PropTypes.func,
}
