import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'
import Collapse from '@ui/data-display/Collapse'

export default function SortableItemPanel({
  children,
  className = '',
  deleteClass,
  deleteDisabled,
  disabled,
  editClass,
  editDisabled,
  extra,
  innerClass,
  isDragging,
  onDelete,
  onEdit,
  onToggle,
  title,
}) {
  return (
    <Collapse.Panel
      className={`flex-grow ${
        isDragging
          ? 'z-20 overflow-hidden shadow-xl ring-2 ring-primary-500'
          : 'z-0 shadow-none ring-0'
      } ${className}`}
      innerClass={innerClass}
      extra={
        <div className="flex flex-row items-center gap-2">
          {extra}
          {onEdit && (
            <Button
              className={editClass}
              onClick={onEdit}
              icon="pen-to-square"
              variant="primary"
              disabled={editDisabled}
            />
          )}
          {onDelete && (
            <Button
              className={deleteClass}
              onClick={onDelete}
              icon="trash-alt"
              variant="danger-light"
              disabled={deleteDisabled}
            />
          )}
        </div>
      }
      onToggle={onToggle}
      title={title}
      disabled={disabled}
    >
      <div className="flex flex-col gap-4">{children}</div>
    </Collapse.Panel>
  )
}
SortableItemPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  deleteClass: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  editClass: PropTypes.string,
  editDisabled: PropTypes.bool,
  extra: PropTypes.node,
  innerClass: PropTypes.string,
  isDragging: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onToggle: PropTypes.func,
  title: PropTypes.node,
}
