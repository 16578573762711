import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Empty from '@ui/data-display/Empty'

import { SortableItem } from '.'
import Field from '../Field'
import { useRules } from '../validationHooks'
import { SortableListContext } from './SortableListContext'

// Default function to find the index of an item in the list
function defaultFindIndex(items = [], id) {
  return items.findIndex(item => item.id === id)
}

// Default function to get the id of an item
function defaultGetItemId(item) {
  return item.id
}

export default function SortableListController({
  className = '',
  disabled,
  emptyDescription,
  emptyTitle,
  findIndex = defaultFindIndex,
  getItemId = defaultGetItemId,
  handleClass, // Class of the handle (default: '')
  handleVerticalAlign, // Vertical alignment of the handle (default: center)
  help,
  itemClass, // Extra classes for the item (default: '')
  name,
  label,
  renderAddItem,
  renderItem,
  showEmpty = false, // Whether to show the empty state (default: false)
  showItemHandle, // Whether to show the handle of the item (default: true)
  required,
  validate,
  shouldUnregister,
}) {
  const { control } = useFormContext()

  const rules = useRules({
    required,
    validate,
  })

  const { fields, move, append, prepend, remove } = useFieldArray({
    name,
    shouldUnregister,
    rules,
  })

  const handleDragEnd = React.useCallback(
    event => {
      if (typeof findIndex !== 'function') return
      const { active, over } = event

      if (!active || !over) return

      if (active?.id !== over?.id) {
        const oldIndex = findIndex(fields, active.id)
        const newIndex = findIndex(fields, over.id)

        move(oldIndex, newIndex)
      }
    },
    [fields, findIndex, move]
  )

  if (typeof renderItem !== 'function') {
    console.warn('SortableList: missing renderItem prop') // eslint-disable-line no-console
    return null
  }

  if (typeof getItemId !== 'function') {
    console.warn('SortableList: missing getItemId prop') // eslint-disable-line no-console
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ fieldState }) => {
        return (
          <div className={`flex flex-col gap-4 ${className}`}>
            <Field label={label} help={help} error={fieldState.error?.['root']}>
              <SortableListContext
                disabled={disabled}
                handleDragEnd={handleDragEnd}
                items={fields}
                name={name}
              >
                <div className={`isolate flex flex-col gap-2 ${className}`}>
                  {!fields.length > 0 && showEmpty && (
                    <Empty label={emptyTitle} description={emptyDescription} />
                  )}

                  {fields.map((field, index) => (
                    <SortableItem
                      className={itemClass}
                      disabled={disabled}
                      handleClass={handleClass}
                      handleVerticalAlign={handleVerticalAlign}
                      id={getItemId(field)}
                      key={field.id}
                      showHandle={showItemHandle}
                    >
                      {renderItem({
                        field,
                        index,
                        fields,
                        disabled,
                        remove,
                        error: fieldState.error?.[index],
                      })}
                    </SortableItem>
                  ))}
                </div>
              </SortableListContext>
            </Field>
            {renderAddItem && !disabled ? (
              <div>{renderAddItem({ append, prepend, fields })}</div>
            ) : null}
          </div>
        )
      }}
    />
  )
}

SortableListController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  emptyDescription: PropTypes.string,
  emptyTitle: PropTypes.string,
  findIndex: PropTypes.func,
  getItemId: PropTypes.func,
  handleClass: PropTypes.string,
  handleVerticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  help: PropTypes.string,
  itemClass: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func,
  renderAddItem: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  shouldUnregister: PropTypes.bool,
  showEmpty: PropTypes.bool,
  showItemHandle: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
