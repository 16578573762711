import PropTypes from 'prop-types'
import React from 'react'

import { arrayMove } from '@dnd-kit/sortable'
import { isEmpty } from 'lodash'

import SortableItem from './SortableItem'
import { SortableListContext } from './SortableListContext'

// Default function to find the index of an item in the list
// function defaultFindIndex(items = [], id) {
//   return items.findIndex(item => item.id === id)
// }

// Default function to get the id of an item
function defaultGetItemId(item) {
  return item.id
}

export default function SortableList({
  className = '', // Extra classes for the list (default: '')
  disabled, // Whether the list is disabled (default: false)
  // findIndex = defaultFindIndex, // Function to find the index of an item in the list (default: defaultFindIndex)
  getItemId = defaultGetItemId, // Function to get the id of an item (default: defaultGetItemId)
  handleClass = '', // Class of the handle (default: '')
  handleVerticalAlign, // Vertical alignment of the handle (default: center)
  itemClass = '', // Extra classes for the item (default: '')
  items: inputItems = [], // List of items to render (default: [], but required in practice)
  name, // Name of the list (**required**)
  onDragEnd, // Callback when the drag ends (default: null)
  renderItem, // Function to render an item (**required**)
  showItemHandle, // Whether to show the handle of the item (default: true)
}) {
  const items = React.useMemo(() => {
    return inputItems.map(item => ({
      ...item,
      id: getItemId(item),
    }))
  }, [inputItems, getItemId])

  // When the drag ends, we update the order of the items in the local state and call the onDragEnd callback
  const handleDragEnd = React.useCallback(
    event => {
      const { active, over } = event

      if (!active || !over) return

      const activeIndex = active?.data?.current?.sortable?.index
      const overIndex = over?.data?.current?.sortable?.index

      if (activeIndex !== overIndex) {
        const newItems = arrayMove(items, activeIndex, overIndex)

        if (typeof onDragEnd === 'function') {
          onDragEnd(newItems)
        }
      }
    },
    [onDragEnd, items]
  )

  // if the renderItem or getItemId functions are not provided, we don't render anything
  if (typeof renderItem !== 'function') {
    console.warn('SortableList: missing renderItem prop') // eslint-disable-line no-console
    return null
  }

  if (typeof getItemId !== 'function') {
    console.warn('SortableList: missing getItemId prop') // eslint-disable-line no-console
    return null
  }

  if (isEmpty(items)) return null

  return (
    <SortableListContext
      disabled={disabled}
      handleDragEnd={handleDragEnd}
      items={items}
      name={name}
    >
      <div className={`isolate flex flex-col ${className}`}>
        {items.map((item, index) => (
          <SortableItem
            className={itemClass}
            handleClass={handleClass}
            handleVerticalAlign={handleVerticalAlign}
            key={item.id}
            id={item.id}
            disabled={disabled}
            showHandle={showItemHandle}
          >
            {renderItem(item, index, { disabled })}
          </SortableItem>
        ))}
      </div>
    </SortableListContext>
  )
}
SortableList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  // findIndex: PropTypes.func.isRequired,
  getItemId: PropTypes.func.isRequired,
  showItemHandle: PropTypes.bool,
  handleClass: PropTypes.string,
  handleVerticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  items: PropTypes.array,
  itemClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func,
}
