import React from 'react'

import { config } from './config'

const EpisodesListSettings = React.lazy(() => import('./Settings'))
const EpisodesListBlock = React.lazy(() => import('./Block'))

export default function EpisodesList(props) {
  return <EpisodesListBlock {...props} />
}

EpisodesList.toolbarItem = {
  ...config,
  component: <EpisodesList />,
}

EpisodesList.craft = {
  displayName: 'EpisodesList',
  props: {
    dynamic: true,
    title: '',
    sortField: 'publishedAt',
    sortOrder: 'desc',
    limit: 8,
    skip: 0,
    episodeDetailPageId: undefined,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['episodes', 'show'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodesListSettings,
  },
}
