import PropTypes from 'prop-types'
import React from 'react'

/**
 * A clickable div with some basic a11y.
 * @param {Object} props - The component props.
 * @param {string} props.as - The component tag name.
 * @param {React.ReactNode} props.children - The component children.
 * @param {string} props.className - The component extra classes.
 * @param {string} props.clickableClass - The component extra classes, but only if is clickable.
 * @param {boolean} props.disabled - Defines if the click should be disabled.
 * @param {React.Ref} props.innerRef - DOM reference provided to the clickable element.
 * @param {string} props.keyCode - Pressed key code expected to trigger the onClick event.
 * @param {Function} props.onClick - Provided onClick event.
 * @param {Function} props.onDoubleClick - Provided onDoubleClick event.
 * @param {string} props.role - The component role.
 * @returns {React.ReactElement} The clickable component.
 */
export default function Clickable({
  as = 'div',
  children,
  className = '',
  clickableClass = '',
  disabled,
  innerRef,
  keyCode = 'Enter',
  onClick,
  onDoubleClick,
  role = 'link',
  ...props
}) {
  if (disabled || typeof (onDoubleClick ?? onClick) !== 'function') {
    return React.createElement(as, { ...props, className }, children)
  }

  return React.createElement(
    as,
    {
      ...props,
      className: `cursor-pointer ${className} ${clickableClass}`,
      onClick,
      onDoubleClick,
      onKeyDown: event => {
        if (event.key === keyCode) {
          onClick(event)
        }
      },
      role,
      tabIndex: 0,
      ref: innerRef,
    },
    children
  )
}
Clickable.propTypes = {
  as: PropTypes.oneOf(['div', 'span', 'td', 'th']),
  children: PropTypes.node,
  className: PropTypes.string,
  clickableClass: PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  keyCode: PropTypes.string,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  role: PropTypes.oneOf(['link']),
}
