import PropTypes from 'prop-types'
import React from 'react'

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useId,
  useMergeRefs,
  useTransitionStatus,
} from '@floating-ui/react'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import CloseButton from '@ui/buttons/CloseButton'

import { useDialogContext } from './hooks'

const variantStyles = {
  base: {
    header: 'border-b border-gray-500',
  },
  warn: {
    header: 'border-b-2 border-warn-400',
  },
  danger: {
    header: 'border-b-4 border-danger-500',
  },
}

/**
 * DialogContent is a component that renders the content of a Dialog.
 * It can be used as a wrapper for any element.
 * @param {React.ReactNode} children - The content of the Dialog.
 * @param {string} childrenClass - Extra classes for the children container.
 * @param {string} boxClass - The class name of the Dialog content.
 * @param {function} buttons - A function that returns the buttons of the Dialog.
 * @param {string} buttonsClass - Extra classes for the buttons container.
 * @param {string} description - The description of the Dialog.
 * @param {function} getBoxClass - A function that returns the class name of the Dialog content.
 * @param {function} getOverlayClass - A function that returns the class name of the Dialog overlay.
 * @param {string} kicker - A kicker for the title.
 * @param {string} okButtonVariant - The variant of the OK button.
 * @param {string} okButtonLabel - The label of the OK button.
 * @param {function} onOkClick - A function that will be called when the OK button is clicked.
 * @param {string} overlayClass - The class name of the Dialog overlay.
 * @param {boolean} showCancelButton - If true, the Dialog will show a Cancel button (if true, buttons prop will be ignored)
 * @param {boolean} showOkButton - If true, the Dialog will show an OK button (if true, buttons prop will be ignored)
 * @param {string} title - The title of the Dialog.
 * @param {string} variant - The variant of the Dialog.
 * @param {React.Ref} ref - A ref to the Dialog content. * @param {React.Ref} ref - A ref to the Dialog content.
 */
const DialogContent = React.forwardRef(function DialogContent(props, propRef) {
  const { t } = useTranslation()
  const {
    title,
    kicker,
    description,
    children,
    childrenClass = '',
    buttons,
    buttonsClass = '',
    boxClass = '',
    getBoxClass,
    overlayClass = '',
    getOverlayClass,
    showCancelButton,
    showOkButton,
    okButtonLabel,
    okButtonVariant,
    onOkClick,
    variant = 'base',
    ...rest
  } = props

  const {
    context: floatingContext,
    setLabelId,
    setDescriptionId,
    open,
    setOpen,
    ...context
  } = useDialogContext()

  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const { isMounted, status } = useTransitionStatus(floatingContext)
  const isOpen = status === 'open'

  const labelId = useId()
  const descriptionId = useId()

  const variantClasses = variantStyles[variant] || variantStyles.base

  // Only sets `aria-labelledby` on the Dialog root element if this component is mounted inside it.
  React.useLayoutEffect(() => {
    if (title) setLabelId(labelId)
    if (description) setDescriptionId(descriptionId)
    return () => setLabelId(undefined)
  }, [title, labelId, setLabelId, description, descriptionId, setDescriptionId])

  // Handles the OK button click event
  const handleOkClick = React.useCallback(() => {
    if (typeof onOkClick === 'function') onOkClick()
    setOpen(false)
  }, [onOkClick, setOpen])

  const overlayClasses =
    typeof getOverlayClass === 'function'
      ? getOverlayClass(isMounted)
      : overlayClass

  const boxClasses =
    typeof getBoxClass === 'function' ? getBoxClass(isMounted) : boxClass

  if (!isMounted) return null

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={`fixed inset-0 flex items-center justify-center bg-black/50 transition-all duration-500 ease-in-out ${
          isOpen
            ? 'z-max opacity-100 backdrop-blur-sm'
            : 'pointer-events-none z-0 opacity-0 backdrop-blur-0'
        } ${overlayClasses}`}
        lockScroll={isOpen}
      >
        <FloatingFocusManager context={floatingContext} modal={isOpen}>
          <div
            {...context.getFloatingProps(rest)}
            className={`flex max-h-[90vh] transform-gpu flex-col rounded-lg bg-white transition-all duration-500 ease-in-out ${
              isOpen
                ? 'translate-y-0 opacity-100 shadow-xl drop-shadow-2xl'
                : 'translate-y-12 opacity-0 shadow-none drop-shadow-none'
            } ${boxClasses}`}
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
          >
            <div
              className={`relative shrink-0 border-b p-6 pb-4 ${variantClasses.header || ''}`}
            >
              <div className="flex flex-col items-stretch gap-1">
                {kicker && (
                  <strong className="text-sm font-bold uppercase text-gray-500">
                    {kicker}
                  </strong>
                )}
                {title && (
                  <h2 className="text-xl font-semibold leading-5" id={labelId}>
                    {title}
                  </h2>
                )}
                {description && (
                  <div className="text-sm text-gray-400" id={descriptionId}>
                    {description}
                  </div>
                )}
              </div>

              <div className="absolute -right-3 -top-3">
                <CloseButton onClick={() => setOpen(false)} variant="solid" />
              </div>
            </div>
            <div
              className={`max-h-[80vh] overflow-y-scroll p-6 shadow-inner hide-scrollbar ${childrenClass}`}
            >
              {typeof children === 'function'
                ? children({ setOpen, open })
                : children}
            </div>
            {(buttons !== undefined || showOkButton) && (
              <div className="flex shrink-0 items-center justify-between gap-4 border-t px-6 py-4">
                <div
                  className={`flex flex-grow items-center justify-between gap-4 ${buttonsClass}`}
                >
                  {showCancelButton || showOkButton ? (
                    <>
                      {showCancelButton && (
                        <Button
                          label={t('cancel')}
                          onClick={() => setOpen(false)}
                          icon="times"
                        />
                      )}
                      {showOkButton && (
                        <Button
                          label={okButtonLabel || t('ok')}
                          onClick={handleOkClick}
                          icon="check"
                          variant={variant || okButtonVariant || 'primary'}
                        />
                      )}
                    </>
                  ) : typeof buttons === 'function' ? (
                    buttons({ setOpen, open })
                  ) : (
                    buttons
                  )}
                </div>
              </div>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})
DialogContent.propTypes = {
  boxClass: PropTypes.string,
  buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  buttonsClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  childrenClass: PropTypes.string,
  description: PropTypes.node,
  getBoxClass: PropTypes.func,
  getOverlayClass: PropTypes.func,
  kicker: PropTypes.node,
  okButtonLabel: PropTypes.string,
  okButtonVariant: PropTypes.string,
  onOkClick: PropTypes.func,
  overlayClass: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showOkButton: PropTypes.bool,
  title: PropTypes.node,
  variant: PropTypes.oneOf(['base', 'warn', 'danger']),
}

export default DialogContent
