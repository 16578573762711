import { isFeatureEnabled } from '@modules/feature-flags/helpers/isFeatureEnabled'

import base from '../base'
import { DesignOptions } from './appearance/DesignOptions'
import { Translate } from './appearance/Translate'
import useAdventistDesignSettingsTabs from './appearance/useAdventistDesignSettingsTabs'
import articleShowcaseLayoutFeaturedWithAside from './assets/ArticlesShowcase/featured-with-aside.svg'
import articleShowcaseLayoutMainFeature from './assets/ArticlesShowcase/main-feature.svg'
import articleShowcaseLayoutSingleWithOneColumn from './assets/ArticlesShowcase/single-with-one-colum.svg'
import articleShowcaseLayoutSingleWithTwoColumns from './assets/ArticlesShowcase/single-with-two-colums.svg'
import cardLayoutDefault from './assets/Card/card-default.svg'
import cardLayoutHorizontal from './assets/Card/card-horizontal.svg'
import cardLayoutOverlay from './assets/Card/card-overlay.svg'
import { DataSourceOptions } from './data-sources/DataSourceOptions'
import { LayoutOptions } from './layouts/LayoutOptions'
import { getThemeColors, themeOptions } from './utils'

/**
 * Represents the Adventist design configuration.
 * @param {Object} options - The options for the Adventist design.
 * @param {Object} options.site - The site.
 * @param {Object} options.featureFlags - The feature flags
 * @returns {Object} - The Adventist design configuration.
 */
export default function ({ site, featureFlags } = {}) {
  const baseConfig = base({ site, featureFlags })

  const isArticleCategoriesEnabled = isFeatureEnabled(
    featureFlags?.['article-categories'],
    { site }
  )
  const isAdventistMinimalThemeSupported = isFeatureEnabled(
    featureFlags?.['adventist-minimal-theme'],
    { site }
  )

  const isDataSourcesEnabled = isFeatureEnabled(
    featureFlags?.['data-sources'],
    { site }
  )

  const isDynamicCategoryResourceEnabled = isFeatureEnabled(
    featureFlags?.['dynamic-category-resource'],
    {
      site,
    }
  )
  const isSiteSearchEnabled = isFeatureEnabled(featureFlags?.['site-search'], {
    site,
  })

  const designVariant = site?.design?.options?.variant ?? 'alps'

  const themes = themeOptions.map(({ name, label }) => ({
    name,
    label,
    colors: getThemeColors({
      colorTheme: name,
      designVariant,
      customThemes: site?.design?.themes,
      designColors: site?.design?.colors,
    }),
  }))

  /**
   * IMPORTANT: keep this updated as the values in the frontend changes
   */
  return {
    ...baseConfig,
    breakpoints: {
      'xs': '350px',
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
    },
    title: 'Adventist design',
    layouts: isAdventistMinimalThemeSupported
      ? {
          LayoutOptions,
        }
      : false,
    ...(isDataSourcesEnabled
      ? {
          dataSources: {
            DataSourceOptions,
          },
        }
      : {}),
    notifications: false,
    namespace: 'designs/adventist',

    menus: {
      itemVariants: {
        link: 'designs/adventist:menuItemVariant_link',
        button: 'designs/adventist:menuItemVariant_button',
      },
      cookieOptions: true,
    },

    appearance: {
      useSettingsTabs: useAdventistDesignSettingsTabs, // This is a hook that returns an array of tabs
      DesignOptions,
      Translate,
      themes,
    },

    fonts: {
      ...baseConfig.fonts,
      weights:
        designVariant === 'news'
          ? ['light', 'normal', 'medium', 'semibold', 'bold', 'black']
          : designVariant === 'minimal'
            ? [
                'thin',
                'extralight',
                'light',
                'normal',
                'medium',
                'semibold',
                'bold',
                'extrabold',
                'black',
              ]
            : ['normal', 'bold'],
      sizes: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '6xl', '8xl'],
    },

    padding: {
      type: 'design', // options: 'all', 'design' or 'defaults'
      options: ['zero', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'],
    },

    columns: [1, 2, 3, 4, 5, 6, 7],

    blocks: {
      ...(isAdventistMinimalThemeSupported
        ? {
            SiteHeader: {
              menus: [
                'primary',
                'secondary',
                ...(designVariant !== 'news' ? ['top'] : []),
              ],
            },
            SiteFooter: true,
          }
        : {}),
      Root: {
        bgColor: true,
        bgImage: true,
        dark: true,
      },
      Section: {
        bgSize: isAdventistMinimalThemeSupported,
        bgSizeCustom: isAdventistMinimalThemeSupported,
        gradient: true,
        offsetBySiteHeader: isAdventistMinimalThemeSupported,
        padding: 'all',
        reverseThemeAppearance: true,
      },
      Container: {
        bgColor: true,
        border: true,
        gradient: true,
        maxWidth: isAdventistMinimalThemeSupported,
      },
      Grid: {
        showGridOptions: true,
      },
      Box: {
        backgroundColor: true,
        bgImageAlign: true,
        bgImageOpacity: true,
        bgOpacity: true,
        border: true,
        dark: true,
        darkBackgroundColor: true,
        hidden: true,
        maxWidth: isAdventistMinimalThemeSupported,
        translationHideable: true,
        wrap: true,
      },
      Card: isAdventistMinimalThemeSupported
        ? {
            bgColor: true,
            ctaIcon: true,
            ctaSize: true,
            ctaTextColor: ['simple', 'plain'],
            imageAlign: true,
            imageAspectRatio: { enabled: ['horizontal', 'overlay'] },
            imageOpacity: true,
            textColor: true,
            titleIcon: true,
            variant: true,
            flipOrder: { enabled: ['horizontal', 'overlay'] },
            imageToTextProportions: {
              enabled: ['horizontal', 'overlay'],
              proportions: [
                '1/1',
                '1/2',
                '1/3',
                '2/3',
                '1/4',
                '3/4',
                '1/5',
                '2/5',
                '3/5',
                '4/5',
                '1/6',
                '5/6',
                '1/7',
                '2/7',
                '3/7',
                '4/7',
                '5/7',
                '6/7',
              ],
            },
            variants: [
              {
                code: 'default',
                preview: cardLayoutDefault,
              },
              {
                code: 'horizontal',
                preview: cardLayoutHorizontal,
              },
              {
                code: 'overlay',
                preview: cardLayoutOverlay,
              },
            ],
          }
        : false,
      Divider: true,
      Header: {
        as: true,
        dynamicTitle: ['pageLong'],
        link: ['content'],
        linkLabel: ['content'],
        subtitle: ['pageLong'],
        kicker: ['pageLong'],
        displayModes: ['content', 'pageLong'],
        ...(designVariant === 'news'
          ? { variants: { content: ['sm', 'md', 'lg'] } }
          : {}),
      },
      SectionHeader: {
        containerMaxWidth: designVariant === 'minimal',
        icon: designVariant === 'minimal',
        position: designVariant === 'minimal',
      },
      Hero: {
        description: true,
        fadeEdgesAtMaxWidth: true,
        fadeEdgesColor: true,
        height: true,
        imageBrightness: true,
        imageCaption: true,
        maxWidth: true,
        offsetBySiteHeader: isAdventistMinimalThemeSupported,
        textAlign: true,
        textBgColor: true,
        textBgGradient: true,
        textBgType: true,
        textColor: true,
        textPosition: true,
      },
      SimpleText: {
        textAlign: true,
      },
      RichText: true,
      Image: {
        aspectRatio: true,
      },
      Accordion: true,
      AccordionItem: true,
      Button: {
        icon: true,
        urlSource: true,
        textColor:
          isAdventistMinimalThemeSupported && designVariant === 'minimal'
            ? ['simple', 'plain']
            : false, // Only for the plain variant
      },
      LinkList: true,
      Breadcrumbs: true,
      HtmlEmbed: true,
      ShareButton: {
        label: true,
      },
      PageList: {
        variants: ['list', 'minimal'],
        showDescription: ['list'],
        showImage: ['list'],
        roundedImage: ['list'],
      },
      Player: true,
      Avatar: {
        personSelect: true,
        showBackground: true,
        showPersonPhone: true,
        showPersonFax: true,
        showPersonDescription: true,
        showPersonEmailLink: true,
      },
      NewsletterSubscription: {
        appearance: true,
        emailLabel: {
          defaultOnEmpty: true,
        },
        termsAcceptLabel: true,
        termsLinkLabel: true,
        termsPage: true,
      },
      NewsletterConfirmation: { appearance: true },
      ...(isDynamicCategoryResourceEnabled
        ? {
            CategoriesList: {
              variants: ['list', 'tags'],
              ...(designVariant === 'news'
                ? { titleVariants: ['sm', 'md', 'lg'] }
                : {}),
            },
          }
        : {}),

      ...(isAdventistMinimalThemeSupported
        ? {
            ContentPlaceholder: {
              bgColor: true,
            },
          }
        : {}),

      Form: true,
      FormCheckbox: true,
      FormInput: {
        files: true,
      },
      FormSelect: true,
      FormTextArea: true,
      LoginError: true,
      LoginForm: true,
      RegistrationForm: true,
      VerifyLogin: true,
      UserProfile: true,
      UserAccountOverview: true,
      GooglePSEResults: true,
      BraveSearchResults: true,
      BraveSearchForm: true,
      ...(isSiteSearchEnabled
        ? { SiteSearchForm: true, SiteSearchResults: true }
        : {}),

      CookieBlockedContent: true,

      // ChurchFinder
      ChurchFinder: {
        showAdvancedSearch: true,
        searchByEntityType: true,
      },
      ChurchDetail: true,
      ChurchHeader: true,
      ChurchContactPersons: true,

      // Articles
      ArticleList: {
        alternateFirstItem: true,
        displayModes: ['list', 'card', 'feedList', 'relatedList'],
        displayModeOptions: {
          list: {
            itemVariants: ['sm', 'md'],
            titleSizes: ['sm', 'md'],
          },
          card: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        filterCurrentArticle: true,
        itemBackgroundColor: ['card'],
        itemBackgroundOpacity: isAdventistMinimalThemeSupported && ['card'],
        ...(designVariant === 'alps' ? { rounded: ['feedList'] } : {}),
        showCategories: true,
        showDescription: true,
        showFilters: true,
        showFlags: true,
        showImage: true,
        useCardPadding: ['card'],
      },
      CategorizedArticles: {
        alternateFirstItem: true,
        displayModes: ['list', 'card', 'feedList', 'relatedList'],
        displayModeOptions: {
          list: {
            itemVariants: ['sm', 'md'],
            titleSizes: ['sm', 'md'],
          },
          card: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        filterCurrentArticle: true,
        itemBackgroundColor: ['card'],
        rounded: ['feedList'],
        showCategories: true,
        showDescription: true,
        showFlags: true,
        showImage: true,
        useCardPadding: ['card'],
      },
      RelatedArticles: {
        alternateFirstItem: true,
        displayModes: ['list', 'card', 'feedList', 'relatedList'],
        displayModeOptions: {
          list: {
            itemVariants: ['sm', 'md'],
            titleSizes: ['sm', 'md'],
          },
          card: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        itemBackgroundColor: ['card'],
        rounded: ['feedList'],
        showCategories: true,
        showDescription: true,
        showImage: true,
        useCardPadding: ['card'],
      },
      ArticlesShowcase: {
        designVariants: ['news', 'minimal'],
        layouts: [
          {
            code: 'main-feature',
            // Used to apply multiple article list filtering strategies
            filters: [
              { code: 'main', limit: 3 },
              { code: 'aside', limit: 3 },
            ],
            preview: articleShowcaseLayoutMainFeature,
          },
          {
            code: 'single-with-one-colum',
            // Used to apply multiple article list filtering strategies
            filters: [{ code: 'main', limit: 3 }],
            preview: articleShowcaseLayoutSingleWithOneColumn,
          },
          {
            code: 'single-with-two-colums',
            // Used to apply multiple article list filtering strategies
            filters: [{ code: 'main', limit: 5 }],
            preview: articleShowcaseLayoutSingleWithTwoColumns,
          },
          {
            code: 'featured-with-aside',
            // Used to apply multiple article list filtering strategies
            filters: [{ code: 'main', limit: 12 }],
            preview: articleShowcaseLayoutFeaturedWithAside,
          },
        ],
        filterCurrentArticle: true,
        showCategories: true,
        showFlags: true,
      },
      ArticleFilters: true,
      ArticleDetail: true,
      ArticleHeader: {
        ...(designVariant === 'alps' ? { variants: ['md', 'lg'] } : {}),
        showOrganization: true,
        showFlag: true,
        locationContext: true,
      },
      ArticleImage: true,
      ...(!isArticleCategoriesEnabled
        ? {
            ArticleFlagsList: {
              showListIcon: true,
            },
          }
        : {}),
      ArticleMap: true,
      ArticleArchive: true,

      // Media library
      EpisodeHeader: {
        variants: ['pageHeader', 'hero'],
      },
      EpisodeDetail: true,
      EpisodePlayer: true,
      EpisodeAudioPlayer: true,
      EpisodesList: {
        columns: ['cards'],
        displayModeOptions: { cards: { itemVariants: ['sm', 'md', 'lg'] } },
        displayModes: ['list', 'relatedList', 'cards', 'scroller'],
        dynamic: true,
        horizontal: ['cards'],
        itemBackgroundColor: ['cards'],
        itemBackgroundOpacity: isAdventistMinimalThemeSupported && ['cards'],
        playInline: ['cards'],
        showPagination: true,
        showShow: true,
        useCardPadding: ['cards'],
      },
      EpisodeFilters: {
        dynamic: true,
      },
      LivestreamPlayer: true,
      ShowAudioPlaylist: true,

      // Events
      EventList: true,
      EventsTeaser: true,
      EventHeader: {
        variants: ['md', 'lg'],
      },
      EventLocationMap: true,
      EventDetail: true,
    },

    colors: {
      theme: {
        ...getThemeColors({
          colorTheme: site?.design?.options?.colorTheme,
          designVariant,
          customThemes: site?.design?.themes,
          designColors: site?.design?.colors,
        }),
      },

      neutral: {
        name: 'Neutral colors',
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#e5e5e5',
        300: '#d4d4d4',
        400: '#a3a3a3',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717',
        950: '#0a0a0a',
      },

      ...(isAdventistMinimalThemeSupported
        ? {
            danger: {
              name: 'Danger',
              50: '#fef2f2',
              100: '#fee2e2',
              200: '#fecaca',
              300: '#fca5a5',
              400: '#f87171',
              500: '#ef4444',
              600: '#dc2626',
              700: '#b91c1c',
              800: '#991b1b',
              900: '#7f1d1d',
              950: '#450a0a',
            },
            warn: {
              name: 'Warning',
              50: '#fffbeb',
              100: '#fef3c7',
              200: '#fde68a',
              300: '#fcd34d',
              400: '#fbbf24',
              500: '#f59e0b',
              600: '#d97706',
              700: '#b45309',
              800: '#92400e',
              900: '#78350f',
              950: '#451a03',
            },
            success: {
              name: 'Success',
              50: '#f0fdf4',
              100: '#dcfce7',
              200: '#bbf7d0',
              300: '#86efac',
              400: '#4ade80',
              500: '#22c55e',
              600: '#16a34a',
              700: '#15803d',
              800: '#166534',
              900: '#14532d',
              950: '#052e16',
            },
          }
        : {}),

      system: baseConfig.colors.system,
    },

    widths: ({ useSabbathColumn } = {}) => {
      // NOTE: These widths are for columns in the frontend using the w-screen-* classes. But here in the backend we are using the w-* classes for better feedback for the user in the backend, as in the editor the widths are relative to the container width, and not the screen width.

      // If the design variant is 'news', or the `useSabbathColumn` option is disabled,
      if (designVariant === 'news' || !useSabbathColumn) {
        // then use a 6 column grid
        return {
          'auto': 'w-auto',
          'full': 'w-full',
          '1/6': 'w-1/6', // frontend is w-screen-1/6
          '2/6': 'w-2/6', // frontend is w-screen-2/6
          '3/6': 'w-3/6', // frontend is w-screen-3/6
          '4/6': 'w-4/6', // frontend is w-screen-4/6
          '5/6': 'w-5/6', // frontend is w-screen-5/6
        }
      }

      // Otherwise, use a 7 column grid by default
      return {
        'auto': 'w-auto',
        'full': 'w-full',
        '1/7': 'w-1/7', // frontend is w-screen-1/7
        '2/7': 'w-2/7', // frontend is w-screen-2/7
        '3/7': 'w-3/7', // frontend is w-screen-3/7
        '4/7': 'w-4/7', // frontend is w-screen-4/7
        '5/7': 'w-5/7', // frontend is w-screen-5/7
        '6/7': 'w-6/7', // frontend is w-screen-6/7
      }
    },

    buttons: {
      variants: ['default', 'lighter', 'outline', 'simple', 'plain'],
      sizes: ['sm', 'md', ...(designVariant === 'minimal' ? ['lg'] : [])],
      iconPositions: ['left', 'right'],
    },

    form: {
      variants: [
        'light',
        'dark',
        'underline-light',
        'underline-dark',
        'underline-theme',
      ],
    },

    icons: [
      'address-card',
      'arrow-bracket-left',
      'arrow-bracket-right',
      'arrow-down',
      'arrow-long-left',
      'arrow-long-right',
      'arrow-rotate-right',
      'arrow-short-left',
      'arrow-short-right',
      'arrow-up-right-from-square',
      'asterisk',
      'backward-step',
      'brazilian-real-sign',
      'calendar-days',
      'calendar',
      'check',
      'chevron-down',
      'chevron-left',
      'chevron-right',
      'church',
      'cion',
      'circle-check',
      'circle-exclamation',
      'circle-info',
      'circle-small',
      'circle-x',
      'clock',
      'close',
      'coins',
      'dollar-sign',
      'download',
      'envelope',
      'euro-sign',
      'external-link-square',
      'forward-step',
      'franc-sign',
      'gear',
      'google',
      'image-slash',
      'language-alps',
      'language-news',
      'location-dot',
      'location-pin',
      'magnifying-glass',
      'map-marker-alt',
      'memo-circle-check',
      'menu',
      'message',
      'pause',
      'people-group',
      'peso-sign',
      'play',
      'plus',
      'route',
      'rupee-sign',
      'search',
      'share',
      'spinner-third',
      'sterling-sign',
      'tag',
      'ticket',
      'undo',
      'user',
      'volume',
      'volume-xmark',
      'xmark',
      'yen-sign',
    ],
  }
}
